import React from 'react'
import ErrorPage from '../components/system/error/error-page'
import Layout from '../components/system/layout'
import Meta from '../components/system/meta'

const NotFound = () => {
  return (
    <Layout>
      <Meta title="Page Not Found" />
      <ErrorPage title="Not Found" message="That page could not be found." />
    </Layout>
  )
}

export default NotFound
