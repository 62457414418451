import React from 'react'
import styled from 'styled-components'
import breakpoints from '../../../styles/breakpoints'
import Container from '../container/container'
import { colors, fonts } from '../../../styles/theme'

const HeroBackground = styled.div`
  background-color: ${colors.primary900};
  background-size: cover;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('/images/pac-hero.jpg');
  background-position: center 70%;

  height: 200px;
  position: relative;
  box-shadow: ${props => props.theme.shadows.close};

  @media (min-width: ${breakpoints.sm}) {
    height: 250px;
  }

  @media (min-width: ${breakpoints.md}) {
    height: 250px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 300px;
  }

  @media (min-width: ${breakpoints.xl}) {
    height: 350px;
  }

  @media (min-width: ${breakpoints.ultrawide}) {
    height: 500px;
    background-position: center 80%;
  }
`

const HeroContainer = styled(Container)`
  display: grid;
  align-content: center;
  height: 100%;
  color: ${props => props.theme.colors.typography.light.text};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${fonts.raleway};
    font-weight: 300;

    b {
      font-weight: 700;
    }
  }
`

const PACHero = ({ children }: { children: any }) => (
  <HeroBackground>
    <HeroContainer>{children}</HeroContainer>
  </HeroBackground>
)

export default PACHero
