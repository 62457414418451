import React from 'react'
import styled from 'styled-components'
import PACHero from '../generic-hero/pac-hero'
import Container from '../container/container'
import breakpoints from '../../../styles/breakpoints'
import Exclamation from './exclamation.svg'

const ErrorContainer = styled(Container)`
  display: grid;
  grid-template-areas:
    'exclamation'
    'text';
  place-items: center;
  text-align: center;

  padding-top: ${props => props.theme.spacing.base600};
  padding-bottom: ${props => props.theme.spacing.base600};

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr auto;
    grid-template-areas: 'text exclamation';
    justify-items: start;
    text-align: left;
  }
`

const Text = styled.div`
  grid-area: text;
`

const StyledExclamation = styled(Exclamation)`
  grid-area: exclamation;
  opacity: 0.5;
  width: 150px;
  height: auto;

  @media (min-width: ${breakpoints.sm}) {
    width: 100%;
  }
`

const ErrorPage = ({ title, message }: { title: string; message: string }) => (
  <>
    <PACHero>
      <h1>
        <strong>{title}</strong>
      </h1>
    </PACHero>
    <ErrorContainer>
      <StyledExclamation />
      <Text>
        <h2>Whoops...</h2>
        <p>{message}</p>
      </Text>
    </ErrorContainer>
  </>
)

export default ErrorPage
